#mapid {
  height: 500px;
  width: 100%;
  left: 0;
  z-index: 0;
}

.map2 {
  position: relative;
  overflow: hidden;
  width: 100%;
  left: 0;
  height: 500px;
  background-color: rgb(229, 227, 223);
}

.promoImage {
  height: 508px;
}

.promoImage img {
  object-fit: cover;
  height: 100%;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: rgb(228,197,144) !important;
}